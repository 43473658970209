const getIsUnavailableDeliveryWithinZip = ({
  zipCode,
  isUnavailableForDeliveryLa,
  isUnavailableForDeliverySfBay,
}: {
  zipCode: string;
  isUnavailableForDeliveryLa?: boolean;
  isUnavailableForDeliverySfBay?: boolean;
}): boolean => {
  const LA_ZIPCODES = [
    '91303',
    '91305',
    '91316',
    '91436',
    '90717',
    '90049',
    '91016',
    '91605',
    '91606',
    '90272',
    '90274',
    '91402',
    '90275',
    '91335',
    '90731',
    '90732',
    '90402',
    '91403',
    '91352',
    '91356',
    '90290',
    '90505',
    '91401',
    '91405',
    '91406',
    '91411',
    '91306',
    '91364',
    '91367',
    '91371',
    '91001',
    '90209',
    '90210',
    '90212',
    '90213',
    '91501',
    '91502',
    '91504',
    '91505',
    '91506',
    '90231',
    '90245',
    '91201',
    '91202',
    '91203',
    '91206',
    '91207',
    '91208',
    '91221',
    '91226',
    '90710',
    '90254',
    '91011',
    '90260',
    '90024',
    '90025',
    '90045',
    '90046',
    '90064',
    '90066',
    '90067',
    '90068',
    '90073',
    '90077',
    '90095',
    '90266',
    '90267',
    '90292',
    '91601',
    '91602',
    '91101',
    '91103',
    '91104',
    '91105',
    '91106',
    '91107',
    '91125',
    '90293',
    '90094',
    '90277',
    '90278',
    '91108',
    '90401',
    '90403',
    '90404',
    '90405',
    '90406',
    '90408',
    '91423',
    '91604',
    '90501',
    '90502',
    '90503',
    '90504',
    '90506',
    '91608',
    '91607',
    '90291',
    '90069',
    '90211',
    '90230',
    '90232',
    '90247',
    '90248',
    '90249',
    '91204',
    '91205',
    '91210',
    '90250',
    '90251',
    '90301',
    '90302',
    '90303',
    '90304',
    '90305',
    '90306',
    '90307',
    '90308',
    '90310',
    '90004',
    '90005',
    '90008',
    '90010',
    '90016',
    '90019',
    '90020',
    '90027',
    '90028',
    '90029',
    '90031',
    '90034',
    '90035',
    '90036',
    '90038',
    '90039',
    '90041',
    '90042',
    '90043',
    '90044',
    '90047',
    '90048',
    '90056',
    '90061',
    '90065',
    '91030',
    '90003',
    '90006',
    '90007',
    '90011',
    '90012',
    '90013',
    '90014',
    '90015',
    '90017',
    '90018',
    '90021',
    '90023',
    '90026',
    '90033',
    '90037',
    '90057',
    '90058',
    '90062',
    '90071',
    '90079',
    '90089',
    '90270',
  ];
  const SF_BAY_ZIPCODES = [
    '94701',
    '94002',
    '94010',
    '94022',
    '94025',
    '94027',
    '94028',
    '94061',
    '94062',
    '94063',
    '94065',
    '94070',
    '94301',
    '94303',
    '94304',
    '94305',
    '94306',
    '94401',
    '94402',
    '94403',
    '94404',
    '94102',
    '94103',
    '94104',
    '94105',
    '94107',
    '94108',
    '94109',
    '94110',
    '94111',
    '94112',
    '94114',
    '94115',
    '94116',
    '94117',
    '94118',
    '94121',
    '94122',
    '94123',
    '94124',
    '94127',
    '94129',
    '94130',
    '94131',
    '94132',
    '94133',
    '94134',
    '94143',
    '94158',
    '94501',
    '94601',
    '94602',
    '94606',
    '94607',
    '94608',
    '94609',
    '94610',
    '94611',
    '94612',
    '94618',
    '94619',
    '94702',
    '94703',
    '94704',
    '94705',
    '94706',
    '94707',
    '94708',
    '94709',
    '94710',
    '94720',
    '94517',
    '94038',
    '94037',
    '94520',
    '94519',
    '94526',
    '94528',
    '94569',
    '94521',
    '94044',
    '94066',
    '94598',
    '94030',
    '94507',
    '94518',
    '94523',
    '94525',
    '94596',
    '94541',
    '94553',
    '94572',
    '94597',
    '94014',
    '94595',
    '94547',
    '94015',
    '94080',
    '94578',
    '94580',
    '94564',
    '94556',
    '94806',
    '94549',
    '94575',
    '94005',
    '94579',
    '94801',
    '94605',
    '94577',
    '94803',
    '94563',
    '94621',
    '94603',
    '94805',
    '94502',
    '94530',
    '94804',
    '94613',
    '94850',
    '95141',
    '95138',
    '95119',
    '95123',
    '95032',
    '95129',
    '95122',
    '95110',
    '95192',
    '95120',
    '95135',
    '95139',
    '95070',
    '95118',
    '95030',
    '95014',
    '95130',
    '95127',
    '95124',
    '95136',
    '95008',
    '95148',
    '95111',
    '94087',
    '95121',
    '94024',
    '94086',
    '95125',
    '95117',
    '94085',
    '94040',
    '94041',
    '94550',
    '95050',
    '95128',
    '95113',
    '95116',
    '95051',
    '95126',
    '95053',
    '95133',
    '94043',
    '94089',
    '95112',
    '95054',
    '95132',
    '95131',
    '94551',
    '94586',
    '95134',
    '95035',
    '94019',
    '94539',
    '94566',
    '94018',
    '94538',
    '94560',
    '94536',
    '94582',
    '94568',
    '94941',
    '94506',
    '94904',
    '94920',
    '94588',
    '94583',
    '94965',
    '94903',
    '94930',
    '94555',
    '94960',
    '94587',
    '94542',
    '94957',
    '94552',
    '94544',
    '94925',
    '94545',
    '94977',
    '94901',
    '94964',
    '94939',
    '94546',
    '94954',
    '95476',
    '94952',
    '94559',
    '94947',
    '94503',
    '94945',
    '91301',
    '91302',
    '94510',
    '94949',
    '94589',
    '94592',
    '94591',
    '94590',
  ];
  if (isUnavailableForDeliveryLa && isUnavailableForDeliverySfBay) return true;
  if (isUnavailableForDeliveryLa) {
    return LA_ZIPCODES.includes(zipCode);
  }
  if (isUnavailableForDeliverySfBay) {
    return SF_BAY_ZIPCODES.includes(zipCode);
  }
  return false;
};
export default getIsUnavailableDeliveryWithinZip;
