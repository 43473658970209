import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import moment from 'infra/moment';
import {humanDayOfWeek} from 'infra/formatters/time';
import {ClientSettingsContext} from 'web/hooks/useClientSettings';

import {DropdownSelector} from '../dropdown_selector';

export default class FulfillmentDayDropdown extends Component {
  static contextType = ClientSettingsContext;
  /**
   * @type {React.ContextType<typeof ClientSettingsContext>}
   */
  context;

  handleSelect(option) {
    if (option.type === 'day') {
      this.props.onChangeDay(option.data);
    } else if (option.type === 'preorder') {
      this.props.onClickPreorderPeriod(option.data);
    }
  }

  buildOption({day, availability, label, description, type, preorderPeriod}) {
    let props;
    if (type === 'preorder') {
      props = {
        isSelected:
          preorderPeriod.firstPreorderDay <= this.props.currentFulfillmentDay &&
          preorderPeriod.endDay >= this.props.currentFulfillmentDay,
        isPreorderPeriod: true,
        isSelectable: true,
      };
    } else {
      props = {
        isSelected: day === this.props.currentFulfillmentDay,
        isSelectable: availability !== 'none',
        isPreorderPeriod: false,
      };
    }

    return {
      props,
      value:
        type && type === 'preorder'
          ? {
              type: 'preorder',
              data: preorderPeriod,
            }
          : {
              type: 'day',
              data: day,
            },
      contents: (
        <div data-day={day || preorderPeriod.name}>
          <h3
            className={classNames('basket__fulfillment-day-dropdown__item-heading', {
              'is-unavailable': availability === 'none',
            })}
          >
            {label}
          </h3>
          <div
            className={classNames(
              'basket__fulfillment-day-dropdown__item-availability-description',
              {'is-partially-available': availability === 'partial'},
            )}
          >
            {description}
          </div>
        </div>
      ),
    };
  }

  buildOptions() {
    const showPreorder = this.props.showPreorderPeriods ?? true;
    const preorderPeriods = showPreorder ? this.props.dropdownPreorderPeriods : [];

    const options = this.props.dropdownDays.concat(preorderPeriods || []);
    return options.map(this.buildOption.bind(this));
  }

  render() {
    const clientSettings = this.context;

    return (
      <DropdownSelector
        selectedValue={`${humanDayOfWeek(
          this.props.currentFulfillmentDay,
          clientSettings.tzid,
        )}, ${moment(this.props.currentFulfillmentDay).format('M/D')}`}
        className="basket__fulfillment-day-dropdown-selector"
        title="Choose a delivery date"
        handleSelect={this.handleSelect.bind(this)}
        options={this.buildOptions()}
      />
    );
  }
}

FulfillmentDayDropdown.propTypes = {
  currentFulfillmentDay: PropTypes.string.isRequired,
  fulfillmentDaySummaries: PropTypes.array.isRequired,
  dropdownDays: PropTypes.array.isRequired,
  dropdownPreorderPeriods: PropTypes.array,
  onClickPreorderPeriod: PropTypes.func.isRequired,
  onChangeDay: PropTypes.func.isRequired,
  showPreorderPeriods: PropTypes.bool,
};

FulfillmentDayDropdown.defaultProps = {
  dropdownPreorderPeriods: [],
};
