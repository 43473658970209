import React, {Component} from 'react';
import PropTypes from 'prop-types';

import AvailabilityPopover from 'web/components/availability_popover';

export default class UnavailableBasketItem extends Component {
  handleRemove() {
    this.props.onEditQuantity({
      productId: this.props.product.id,
      quantity: 0,
    });
  }

  render() {
    return (
      <div className="js-basket-item summary-item unavailable-basket-item clearfix">
        <div className="summary-item__photo">
          <img src={this.props.product.photoUrl} />
        </div>

        <div className="summary-item__details">
          <div className="summary-item__producer producer">
            <a href={this.props.product.vendor.url}>{this.props.product.vendor.name}</a>
          </div>
          <div className="summary-item__name name">
            <a href={this.props.product.url}>{this.props.product.name}</a>
          </div>
        </div>

        <div className="summary-item__info-control-spacer clearfix" />

        <div className="summary-item__quantity-controls">
          {this.props.quantity} x {this.props.retailUnits}
        </div>

        <div className="summary-item__info-control-spacer clearfix" />

        <div className="summary-item__actions">
          <span className="summary-item__remove" onClick={this.handleRemove.bind(this)}>
            Remove
          </span>
          <AvailabilityPopover
            availabilitiesByDay={this.props.availabilitiesByDay}
            tzid={this.props.tzid}
            preorderPeriod={this.props.preorderPeriod}
            isDeliveryRestricted={this.props.product.isDeliveryRestricted}
            deliveryRestrictedMinimumWindowStart={this.props.deliveryRestrictedMinimumWindowStart}
            deliveryRestrictionMessage={this.props.deliveryRestrictionMessage}
          />
        </div>
      </div>
    );
  }
}

UnavailableBasketItem.propTypes = {
  tzid: PropTypes.string.isRequired,
  availabilitiesByDay: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  retailUnits: PropTypes.string.isRequired,
  onEditQuantity: PropTypes.func.isRequired,
  preorderPeriod: PropTypes.object,
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    photoUrl: PropTypes.string.isRequired,
    vendor: PropTypes.shape({
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    isDeliveryRestricted: PropTypes.bool.isRequired,
  }).isRequired,
  /**
   * This is a numeric value 0-23 which represents the hour (inclusive) which a restricted item becomes available.
   * It is passed down so that the availability text that is rendered can display the hour to a user.
   */
  deliveryRestrictedMinimumWindowStart: PropTypes.number,
  deliveryRestrictionMessage: PropTypes.string,
};
