import React from 'react';

import getIsUnavailableDeliveryWithinZip from 'domain/catalog/products/helpers/availability_helpers/get_is_unavailable_delivery_within_zip';
import {getFirstAvailableDay} from 'web/helpers/product';
import {UpcomingPreorderPeriod} from 'web/components/market_layout/store_builder';
import {FulfillmentDay, FulfillmentDaysByDay, Item, Products} from 'web/basket/basket_page';

import UnavailableBasketItem from '../unavailable_basket_item';

interface CommonProps {
  clientSettings: {
    tzid: string;
  };
  products: Products | undefined;
  currentFulfillmentDay: string | undefined;
  fulfillmentDaySummaries: FulfillmentDay[];
  upcomingPreorderPeriods: UpcomingPreorderPeriod[] | undefined;
  fulfillmentDaysByDay: FulfillmentDaysByDay;
  handleEditQuantity: (args: {productId: string; quantity: number}) => void;
  deliveryRestrictedMinimumWindowStart: number;
  zipCode: string;
}

interface UnavailableItemsListProps extends CommonProps {
  items: Item[];
  header: string;
}

const UnavailableItemsList: React.FC<UnavailableItemsListProps> = ({
  items,
  header,
  currentFulfillmentDay,
  clientSettings,
  products,
  fulfillmentDaySummaries,
  upcomingPreorderPeriods,
  fulfillmentDaysByDay,
  handleEditQuantity,
  deliveryRestrictedMinimumWindowStart,
  zipCode,
}) => (
  <div className="unavailable-basket-items">
    <div className="unavailable-basket-items__header">
      <span className="unavailable-basket-items__heading">{header}</span>
      Try another delivery date!
    </div>
    {items.map((item) => {
      const product = products?.[item.product.id];
      const firstAvailableDay = getFirstAvailableDay({
        product,
        currentFulfillmentDay,
        fulfillmentDaySummaries,
        upcomingPreorderPeriods,
        fulfillmentDaysByDay,
      });
      const preorderPeriod = upcomingPreorderPeriods?.find((preorder) =>
        preorder.preorderDays.includes(firstAvailableDay),
      );

      return (
        <UnavailableBasketItem
          availabilitiesByDay={product?.availabilitiesByDay}
          onEditQuantity={handleEditQuantity}
          preorderPeriod={preorderPeriod}
          item={item}
          key={`${product?.id ?? ''}`}
          product={product}
          quantity={item.quantity}
          retailUnits={product?.retailUnits}
          tzid={clientSettings.tzid}
          deliveryRestrictedMinimumWindowStart={deliveryRestrictedMinimumWindowStart}
          deliveryRestrictionMessage={
            getIsUnavailableDeliveryWithinZip({
              zipCode,
              isUnavailableForDeliveryLa: product?.isUnavailableForDeliveryLa,
              isUnavailableForDeliverySfBay: product?.isUnavailableForDeliverySfBay,
            })
              ? `This product is unavailable for delivery in your area`
              : undefined
          }
        />
      );
    })}
  </div>
);

export default UnavailableItemsList;
