import classNames from 'classnames';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import moment from 'infra/moment';
import {ClientSettingsContext} from 'web/hooks/useClientSettings';
import {humanDollarsFromCents} from 'infra/formatters/money';
import {humanDayOfWeek} from 'infra/formatters/time';

export default class CheckoutButtons extends Component {
  static contextType = ClientSettingsContext;
  /**
   * @type {React.ContextType<typeof ClientSettingsContext>}
   */
  context;

  constructor(props) {
    super(props);
    this.state = {
      showContinueShopping: false,
      isModalOpen: false,
    };
  }

  handleCheckoutClick(e, selectFulfillmentUrl = '') {
    const {isBelowMinimumForNewOrder, user} = this.props;

    if (user == null && !isBelowMinimumForNewOrder) {
      this.props.handleAuthFlow();
    } else {
      window.metrics.track('Checkout Started', this.props.checkoutStartedProperties);
      setTimeout(() => {
        window.location.href = selectFulfillmentUrl;
      }, 300);
    }
    this.finishCheckout();
  }

  finishCheckout() {
    if (this.props.isBelowMinimumForNewOrder) {
      this.setState({showContinueShopping: true});
    }
  }

  onAuthenticate() {
    let selectFulfillmentUrl = '/basket/start_checkout';
    if (this.props.addingToOrder) {
      selectFulfillmentUrl += '?newOrder=true';
    }

    if (!this.props.isBelowMinimumForNewOrder && !this.props.isShoppingInPhantomDay) {
      window.location.href = selectFulfillmentUrl;
    }

    this.finishCheckout();
  }

  onCloseModal() {
    this.setState({isModalOpen: false});
  }

  render() {
    const clientSettings = this.context;

    let selectFulfillmentUrl = '/basket/start_checkout';
    let checkoutButtonClass, checkoutButtonText;
    if (this.props.addingToOrder) {
      checkoutButtonText = 'Create new order';
      checkoutButtonClass =
        'link-button next checkout-buttons__button checkout__link checkout-buttons__new-order ';
      selectFulfillmentUrl += '?newOrder=true';
    } else {
      checkoutButtonText = this.props.isShoppingInPhantomDay
        ? 'Check out'
        : `Check out for ${humanDayOfWeek(
            this.props.currentFulfillmentDay,
            clientSettings.tzid,
          )} ${moment(this.props.currentFulfillmentDay).format('M/D')}`;
      checkoutButtonClass = 'button next checkout-buttons__button';
    }

    if (this.props.user == null) {
      checkoutButtonText = 'Sign up to check out';
    }

    const checkoutButtonProperties = {
      id: 'checkout-button',
      className: classNames(
        {
          'button-is-disabled':
            !this.props.isEligibleToCheckout ||
            this.props.isBelowMinimumForNewOrder ||
            this.props.isShoppingInPhantomDay,
        },
        checkoutButtonClass,
      ),
    };
    if (
      !this.props.isBelowMinimumForNewOrder &&
      !this.props.isShoppingInPhantomDay &&
      this.props.user != null
    ) {
      checkoutButtonProperties.href = selectFulfillmentUrl;
    }

    return (
      <div className="checkout-buttons">
        <div className="checkout-buttons__button-container">
          <button
            disabled={!this.props.isEligibleToCheckout}
            type="button"
            onClick={(e) => {
              this.handleCheckoutClick(e, checkoutButtonProperties.href);
            }}
            {...checkoutButtonProperties}
          >
            {checkoutButtonText}
          </button>
          {!this.props.isEligibleToCheckout && (
            <span className="checkout-buttons__to-place-subscription">
              <p>In order to place a subscription, you need at least</p>
              <p className="checkout-buttons__bold">
                {humanDollarsFromCents(this.props.newOrderOrderMinimum)} of subscription items
                selected
              </p>
            </span>
          )}
          {this.props.isBelowMinimumForNewOrder &&
          !this.props.isShoppingInPhantomDay &&
          (!this.props.addingToOrder || this.state.showContinueShopping) ? (
            <div className="checkout-buttons__continue-shopping">
              <a
                href={`/${this.props.categories[0].id}`}
                className="checkout-buttons__order-minimum"
              >
                {`${humanDollarsFromCents(
                  this.props.newOrderOrderMinimum,
                )} minimum - Continue Shopping`}
              </a>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

CheckoutButtons.propTypes = {
  isBelowMinimumForNewOrder: PropTypes.bool,
  isShoppingInPhantomDay: PropTypes.bool,
  newOrderOrderMinimum: PropTypes.number,
  currentFulfillmentDay: PropTypes.string,
  addingToOrder: PropTypes.bool,
  showContinueShopping: PropTypes.bool,
  categories: PropTypes.array,
  user: PropTypes.object,
  features: PropTypes.array,
  isEligibleToCheckout: PropTypes.bool,
  handleAuthFlow: PropTypes.func,
};
