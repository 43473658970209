import React, {FC} from 'react';
import {useSelector} from 'react-redux';

import {humanDollarsFromCents} from 'infra/formatters/money';

export const BannerMinOrder: FC = () => {
  const orderMinimum = useSelector<{accounting: {orderMinimum: number}}, number>(
    (state) => state.accounting.orderMinimum,
  );

  return (
    <div className="banner__min__order-banner" data-testid="banner-min-to-subscribe">
      <i className="icon icon-subscriptions banner__min__order-icon" />
      <span>
        In order to place a subscription, you need at least {humanDollarsFromCents(orderMinimum)} of
        subscription items selected{' '}
      </span>
    </div>
  );
};
