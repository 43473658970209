import React from 'react';
import PropTypes from 'prop-types';

const EmptyBasket = ({categories}) => (
  <div className="empty-basket raised-tile">
    <div className="basket-view__fulfillment-header">&nbsp;</div>
    <div className="empty-basket__body">
      <div className="empty-basket__title">Your basket is empty</div>
      <a className="empty-basket__continue-shopping-button button" href={`/${categories[0].id}`}>
        Continue Shopping
      </a>
    </div>
  </div>
);

EmptyBasket.propTypes = {
  categories: PropTypes.array.isRequired,
};

export default EmptyBasket;
