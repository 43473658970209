import React, {Component} from 'react';
import PropTypes from 'prop-types';

import moment from 'infra/moment';
import ChoiceSelect from 'web/components/choice_select';
import SubmitButton from 'web/components/submit_button';
import {humanTimeRange} from 'infra/formatters/time';

export default class AddToOrderControls extends Component {
  constructor() {
    super();
    this.state = {isSaving: false};
  }

  handleAddToOrderIdChange(e) {
    this.props.onAddToOrderIdChange(e.target.value);
  }

  handleAddToOrder() {
    this.setState({isSaving: true});
    this.props.onAddToOrder();
  }

  render() {
    const upcomingOrders = this.props.upcomingOrders.filter(
      (upcomingOrder) => upcomingOrder.fulfillmentDay === this.props.currentFulfillmentDay,
    );
    const longDayOfTheWeek = moment
      .tz(this.props.currentFulfillmentDay, 'YYYY-MM-DD', this.props.tzid)
      .format('dddd');
    const shortDayOfWeek = moment(this.props.currentFulfillmentDay).format('ddd');
    const shortDate = moment(this.props.currentFulfillmentDay).format('M/D');

    return (
      <div className="basket-view__add-to-order-row">
        <div className="js-add-to-order-controls basket-view__add-to-order-container">
          {upcomingOrders.length > 1 ? (
            <div className="basket-view__upcoming-orders">
              <label>{`Add to your ${longDayOfTheWeek} ${shortDate} orders`}</label>

              <ChoiceSelect
                value={this.props.addToOrderId}
                onChange={this.handleAddToOrderIdChange.bind(this)}
              >
                {upcomingOrders.map((upcomingOrder) => {
                  let deliveryText = humanTimeRange(
                    upcomingOrder.deliveryWindow.startAt,
                    upcomingOrder.deliveryWindow.endAt,
                    this.props.tzid,
                  );
                  deliveryText += ` Delivery to ${upcomingOrder.deliveryDetails.address}`;
                  if (upcomingOrder.deliveryDetails.isGift) {
                    deliveryText += ' (Gift)';
                  }
                  return (
                    <option value={upcomingOrder.id} key={upcomingOrder.id}>
                      {deliveryText}
                    </option>
                  );
                })}
              </ChoiceSelect>
            </div>
          ) : null}
          <SubmitButton
            disabled={this.props.enableAddToOrder}
            workingLabel="Processing"
            isSaving={this.state.isSaving}
            className="button next checkout-buttons__button"
            onClick={this.handleAddToOrder.bind(this)}
          >
            {`Add To ${shortDayOfWeek} ${shortDate} Order`}
          </SubmitButton>
        </div>
      </div>
    );
  }
}

AddToOrderControls.propTypes = {
  addToOrderId: PropTypes.string.isRequired,
  upcomingOrders: PropTypes.array.isRequired,
  currentFulfillmentDay: PropTypes.string.isRequired,
  tzid: PropTypes.string.isRequired,
  onAddToOrderIdChange: PropTypes.func.isRequired,
  onAddToOrder: PropTypes.func.isRequired,
  enableAddToOrder: PropTypes.bool.isRequired,
};
