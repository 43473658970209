import React, {Component} from 'react';
import classNames from 'classnames';

import {ClientSettingsContext} from 'web/hooks/useClientSettings';
import {transformOfferForTracking} from 'web/helpers/serializers/transform_offer_for_tracking';

import {DropdownSelector} from '../dropdown_selector';

export default class FulfillmentOfferDropdown extends Component {
  static contextType = ClientSettingsContext;
  /**
   * @type {React.ContextType<typeof ClientSettingsContext>}
   */
  context;

  handleSelectFulfillmentOffer(value) {
    const clientSettings = this.context;

    this.props.onChangeFulfillmentOffer(value.offer);

    window.metrics.track('Delivery Window Selected', {
      windowChosen: transformOfferForTracking({tzid: clientSettings.tzid, offer: value}),
    });
  }

  handleOptionsViewed() {
    const clientSettings = this.context;

    window.metrics.track('Delivery Window Options Viewed', {
      windowsViewed: this.props.fulfillmentOffers?.map((offer) =>
        transformOfferForTracking({tzid: clientSettings.tzid, offer}),
      ),
      windowPreviouslyChosen: this.props.selectedFulfillmentOffer
        ? transformOfferForTracking({
            tzid: clientSettings.tzid,
            offer: this.props.selectedFulfillmentOffer,
          })
        : undefined,
    });
  }

  render() {
    return (
      <DropdownSelector
        onOptionsViewed={this.handleOptionsViewed.bind(this)}
        defaultValue="Pick a Time"
        selectedValue={
          this.props.selectedFulfillmentOffer ? this.props.selectedFulfillmentOffer.label : null
        }
        className="basket__fulfillment-offer-dropdown-selector"
        handleSelect={this.handleSelectFulfillmentOffer.bind(this)}
        title="Choose a delivery time"
        options={this.props.fulfillmentOffers.map((fulfillmentOffer) => ({
          props: {
            isSelected:
              this.props.selectedFulfillmentOffer &&
              fulfillmentOffer.label === this.props.selectedFulfillmentOffer.label,
            isSelectable: fulfillmentOffer.status === 'available',
          },
          value: fulfillmentOffer,
          contents: (
            <div className="basket__fulfillment-offer-dropdown__item">
              <span
                className={classNames('basket__fulfillment-offer-dropdown__item-label', {
                  'is-unavailable': fulfillmentOffer.status !== 'available',
                })}
              >
                {fulfillmentOffer.label}
              </span>
              <span className="basket__fulfillment-offer-dropdown__item-status">
                {' '}
                {fulfillmentOffer.statusText}
              </span>
            </div>
          ),
        }))}
      />
    );
  }
}
