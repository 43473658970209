import React, {Component} from 'react';
import classNames from 'classnames';

class DropdownSelectorOption extends Component {
  handleSelect() {
    if (this.props.isSelectable) {
      this.props.handleSelect();
    }
  }

  render() {
    return (
      <li
        onClick={this.handleSelect.bind(this)}
        className={classNames(
          'basket__dropdown-selector__item',
          {'is-selected': !this.props.isPreorderPeriod && this.props.isSelected},
          {'is-selectable': this.props.isSelectable},
        )}
      >
        <div>
          {this.props.isSelectable ? (
            <i
              className={classNames(
                'basket__dropdown-selector__item-icon',
                'icon',
                {'icon-circle-checkmark': this.props.isSelected},
                {'icon-sidebar-blank-circle': !this.props.isSelected},
              )}
            />
          ) : null}
          {this.props.children}
        </div>
      </li>
    );
  }
}

export class DropdownSelector extends Component {
  constructor() {
    super();
    this.state = {
      dropdownHidden: true,
    };
  }

  toggleDropdown() {
    this.setState({dropdownHidden: !this.state.dropdownHidden});

    // (@shermam) If the drop down was previously hidden
    // it means that now we are making it visible and we
    // should trigger the `onOptionsViewed` event
    if (this.state.dropdownHidden) {
      this.props.onOptionsViewed?.();
    }
  }

  handleClickOutside() {
    this.setState({dropdownHidden: true});
  }

  handleSelect(value) {
    this.props.handleSelect(value);
    this.setState({
      dropdownHidden: true,
    });
  }

  render() {
    return (
      <div
        className={classNames('basket__dropdown-selector', this.props.className)}
        onBlur={this.handleClickOutside.bind(this)}
        tabIndex="-1"
      >
        <div className="basket__dropdown-selector__header" onClick={this.toggleDropdown.bind(this)}>
          <span
            className={classNames('basket__dropdown-selector__header-text', {
              'is-empty': this.props.selectedValue == null,
            })}
          >
            {this.props.selectedValue ? this.props.selectedValue : this.props.defaultValue}
          </span>
          <span className="basket__dropdown-selector__arrow">{String.fromCharCode(9662)}</span>
        </div>
        <ul
          className={classNames('basket__dropdown-selector__list', {
            hidden: this.state.dropdownHidden,
          })}
        >
          <li className="basket__dropdown-selector__item basket__dropdown-selector__mobile-title">
            <h3 className="basket__dropdown-selector__mobile-title-text">{this.props.title}</h3>
            <i
              className="icon icon-thin-x basket__dropdown-selector__mobile-title-close"
              onClick={this.toggleDropdown.bind(this)}
            />
          </li>
          {this.props.options.map((option, i) => (
            <DropdownSelectorOption
              {...option.props}
              key={i}
              handleSelect={this.handleSelect.bind(this, option.value)}
            >
              {option.contents}
            </DropdownSelectorOption>
          ))}
        </ul>
      </div>
    );
  }
}
