import React, {FC} from 'react';

import Modal from 'web/components/modal';

interface AttendedDeliveryWarningProps {
  onClose: () => void;
  onConfirmAddToOrder: () => void;
}

const AttendedDeliveryWarning: FC<AttendedDeliveryWarningProps> = ({
  onClose,
  onConfirmAddToOrder,
}) => (
  <Modal onClose={onClose} className="attended-delivery-warning">
    <i className="icon icon-alert-warning" />
    <div className="attended-delivery-warning__header">
      ID required in person for alcohol delivery.
    </div>
    <hr className="hr-centered" />
    <div className="attended-delivery-warning__content">The order will not be left unattended.</div>
    <div className="attended-delivery-warning__actions">
      <button type="button" className="button" onClick={onClose}>
        cancel
      </button>
      <button
        type="button"
        className="button attended-delivery-warning__confirm"
        onClick={onConfirmAddToOrder}
      >
        ok
      </button>
    </div>
  </Modal>
);

export default AttendedDeliveryWarning;
