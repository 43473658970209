import React, {FC} from 'react';
import classNames from 'classnames';

import moment from 'infra/moment';
import {AvailabilitiesByDay, PreorderPeriod} from 'web/basket/basket_page';

export interface AvailabilityPopoverProps {
  availabilitiesByDay: AvailabilitiesByDay;
  preorderPeriod?: PreorderPeriod;
  tzid: string;
  isDeliveryRestricted: boolean;
  deliveryRestrictedMinimumWindowStart: number;
  deliveryRestrictionMessage?: string;
}

const getPopoverDays = (
  availabilitiesByDay: AvailabilitiesByDay,
  preorderPeriod?: PreorderPeriod,
): string[] => {
  if (preorderPeriod != null) {
    return preorderPeriod.preorderDays;
  }

  return Object.keys(availabilitiesByDay).slice(0, 7);
};

const AvailabilityPopover: FC<AvailabilityPopoverProps> = ({
  availabilitiesByDay,
  preorderPeriod,
  tzid,
  isDeliveryRestricted,
  deliveryRestrictedMinimumWindowStart,
  deliveryRestrictionMessage: deliveryRestrictionMessageOverride,
}) => {
  const popoverDays = getPopoverDays(availabilitiesByDay, preorderPeriod);

  const availableDaysQuantity = popoverDays.filter(
    (day) => availabilitiesByDay[day]?.status === 'available',
  ).length;

  const deliveryRestrictionMessage = isDeliveryRestricted
    ? `after ${moment(deliveryRestrictedMinimumWindowStart, 'HH').format('ha')}`
    : '';

  const availabilityText: string =
    deliveryRestrictionMessageOverride ||
    (preorderPeriod != null
      ? `Available for ${preorderPeriod.name} preorder`
      : `Available ${availableDaysQuantity} days/week ${deliveryRestrictionMessage}`.trim());

  return (
    <div className="availability-popover__container">
      <span className="availability-popover__container__content">
        <i
          data-testid="availability-popover__status-icon"
          className={classNames('icon icon-clock availability-popover__status-icon', {
            dark: preorderPeriod != null,
          })}
        />
        <span>{availabilityText}</span>
      </span>
      <div
        data-testid="availability-popover__menu"
        className="availability-popover__menu popover popover--with-arrow is-positioned-above"
      >
        <ul className="product-availability-statuses">
          {popoverDays.map((day) => {
            const status = availabilitiesByDay[day]?.status || 'unavailable';
            return (
              <li
                className={classNames(`product-availability-status ${status}`, {
                  preorder: Boolean(preorderPeriod),
                })}
                data-testid="availability-popover__menu-item"
                key={day}
              >
                <div className="status">
                  <div className="indicator" data-testid="availability-popover__menu-indicator" />
                  <div className="day-of-week">
                    {preorderPeriod != null
                      ? moment.tz(day, 'YYYY-MM-DD', tzid).format('M/D')
                      : moment.tz(day, 'YYYY-MM-DD', tzid).format('ddd')}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default AvailabilityPopover;
