import {range} from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import Cents from 'goodeggs-money';

import {humanPriceFromCents} from 'infra/formatters/money';
import ChoiceSelect from 'web/components/choice_select';
import Checkbox from 'web/components/checkbox';
import StrikethroughLineItem from 'web/components/strikethrough_lineitem';
import {ClientSettingsContext} from 'web/hooks/useClientSettings';

const LOW_AVAILABILITY_CUTOFF = 9;

const QuantityControls = ({quantity, availableQuantity, maxQuantity, onChange}) => (
  <div className="summary-item__quantity-controls">
    <ChoiceSelect className="summary-item__quantity" value={quantity || ''} onChange={onChange}>
      {range(Math.min(maxQuantity, availableQuantity)).map((__, index) => {
        const _quantity = index + 1;
        return (
          <option key={_quantity} value={_quantity}>
            {_quantity}
          </option>
        );
      })}
    </ChoiceSelect>
    {availableQuantity <= LOW_AVAILABILITY_CUTOFF ? (
      <div className="summary-item__availability">Only {availableQuantity} left!</div>
    ) : null}
  </div>
);

QuantityControls.propTypes = {
  isGift: PropTypes.bool,
  quantity: PropTypes.number,
  availableQuantity: PropTypes.number,
  maxQuantity: PropTypes.number,
  onChange: PropTypes.func,
};

const SubscribeControls = ({
  active,
  onToggle,
  basketSubscriptionTotal,
  quantitySubscriptionDiscount,
  onClickLearnMore,
}) => {
  const orderMinimum = useSelector((state) => state.accounting.orderMinimum);
  const canCheckout =
    orderMinimum != null &&
    basketSubscriptionTotal != null &&
    new Cents(basketSubscriptionTotal).greaterThanOrEqual(new Cents(orderMinimum));
  return (
    <div className="summary-item__subscription">
      <div
        className="summary-item__subscription-controls"
        data-testid="checkbox-subscribe"
        onClick={onToggle}
      >
        <Checkbox active={active} />
        <i className="icon icon-subscriptions" />
        <span className="subscribe-label">Subscribe</span>
      </div>
      {canCheckout ? (
        <span className="summary-item__subscription__add-more">
          Great! eligible for subscription
        </span>
      ) : (
        <span className="summary-item__subscription__add-more">
          Add{' '}
          <span className="summary-item__bold">
            {orderMinimum != null &&
              basketSubscriptionTotal != null &&
              humanPriceFromCents(
                new Cents(orderMinimum).minus(basketSubscriptionTotal, {maxZero: true}).toNumber(),
              )}
          </span>{' '}
          more to set up subscription
        </span>
      )}
      {!active && (
        <div className="summary-item__subscription__learn-more" onClick={onClickLearnMore}>
          {quantitySubscriptionDiscount ? (
            <span className="summary-item__subscription__savings">
              Save {humanPriceFromCents(quantitySubscriptionDiscount)}{' '}
            </span>
          ) : null}
          Learn More
        </div>
      )}
    </div>
  );
};

SubscribeControls.propTypes = {
  active: PropTypes.bool,
  onToggle: PropTypes.func,
  quantitySubscriptionDiscount: PropTypes.number,
  basketSubscriptionTotal: PropTypes.number,
};

export default class BasketItem extends Component {
  static contextType = ClientSettingsContext;
  /**
   * @type {React.ContextType<typeof ClientSettingsContext>}
   */
  context;
  handleChangeQuantity(e) {
    this.props.onEditQuantity({
      productId: this.props.product.id,
      quantity: e.target.value,
    });
  }

  handleRemove() {
    this.props.onEditQuantity({
      productId: this.props.product.id,
      quantity: 0,
    });
  }

  handleClickSubscribe() {
    this.props.onSetShouldSubscribe({
      productId: this.props.product.id,
      shouldSubscribe: !this.props.item.shouldSubscribe,
      existingSubscriptionId: this.props.item.existingSubscriptionId,
    });
  }

  handleShowSubscriptionModal() {
    const showDiscountContent = this.props.product.quantityOptions.some(
      (option) => option.quantitySubscriptionDiscount > 0,
    );
    this.props.onShowSubscriptionModal(showDiscountContent);
  }

  renderSubtotals() {
    const clientSettings = this.context;
    const hasPromoPricing = this.props.subtotal < this.props.basetotal;
    if (this.props.subscriptionDiscount && !hasPromoPricing) {
      return (
        <div className="summary-item__prices">
          <div className="summary-item__strikethrough-price">
            {humanPriceFromCents(this.props.subtotal)}
            {clientSettings.strikethroughPricing.enableWeb && hasPromoPricing && (
              <StrikethroughLineItem basetotal={this.props.basetotal} />
            )}
          </div>
          <div className="summary-item__price">
            {humanPriceFromCents(this.props.subtotalMinusSubscriptionDiscount)}
          </div>
        </div>
      );
    }
    return (
      <div className="summary-item__price">
        {humanPriceFromCents(this.props.subtotal, {showZeroAsFree: true})}
        {clientSettings.strikethroughPricing.enableWeb && hasPromoPricing && (
          <StrikethroughLineItem basetotal={this.props.basetotal} />
        )}
      </div>
    );
  }

  render() {
    const selectedQuantityOption =
      this.props.product.quantityOptions.find(
        (quantityOption) => quantityOption.quantity === this.props.quantity,
      ) || {};

    return (
      <div
        className="js-basket-item summary-item clearfix"
        data-testid="summary-item"
        data-product-id={this.props.product.id}
      >
        <div className="summary-item__photo">
          <img src={this.props.product.photoUrl} alt={this.props.product.name} />
        </div>

        <div className="summary-item__details">
          {this.props.product.hasVariableProducers ? null : (
            <div className="summary-item__producer producer">
              <a href={this.props.product.currentProducer.url}>
                {this.props.product.currentProducer.name}
              </a>
            </div>
          )}
          <div className="summary-item__name name">
            <a href={this.props.product.url}>{this.props.product.name}</a>
          </div>
          <div className="summary-item__unit unit">
            {this.props.product.retailUnits}
            {this.props.hasBottleDeposit ? '*' : null}
          </div>
        </div>

        <div className="summary-item__info-control-spacer clearfix" />

        <div className="summary-item__controls">
          <QuantityControls
            isGift={this.props.isGift}
            quantity={this.props.quantity}
            availableQuantity={this.props.availableQuantity}
            maxQuantity={this.props.product.maxQuantity}
            onChange={this.handleChangeQuantity.bind(this)}
          />
          {this.props.product.canSubscribe && !this.props.isGift ? (
            <SubscribeControls
              quantitySubscriptionDiscount={selectedQuantityOption.quantitySubscriptionDiscount}
              onClickLearnMore={this.handleShowSubscriptionModal.bind(this)}
              active={this.props.item.shouldSubscribe}
              onToggle={this.handleClickSubscribe.bind(this)}
              basketSubscriptionTotal={this.props.basketSubscriptionTotal}
              item={this.props.item}
            />
          ) : null}
        </div>
        {this.renderSubtotals()}
        <div className="summary-item__info-control-spacer clearfix" />

        <div className="summary-item__actions">
          <span className="summary-item__remove" onClick={this.handleRemove.bind(this)}>
            Remove
          </span>
        </div>
      </div>
    );
  }
}

BasketItem.propTypes = {
  product: PropTypes.object.isRequired,
  isGift: PropTypes.bool,
  availableQuantity: PropTypes.number,
  subtotal: PropTypes.number.isRequired,
  basetotal: PropTypes.number.isRequired,
  item: PropTypes.shape({
    productId: PropTypes.string,
    quantity: PropTypes.number,
    shouldSubscribe: PropTypes.bool,
    existingSubscriptionId: PropTypes.string,
  }),
  onEditQuantity: PropTypes.func,
  onSetShouldSubscribe: PropTypes.func,
  onShowSubscriptionModal: PropTypes.func,
  hasBottleDeposit: PropTypes.bool,
  quantity: PropTypes.number,
  subscriptionDiscount: PropTypes.number,
  subtotalMinusSubscriptionDiscount: PropTypes.number,
  basketSubscriptionTotal: PropTypes.number,
};
